.unhandled-analytics-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5%;
}

.unhandled-analytics-container > h1:nth-child(1) {
    width: 50%;
}

.unhandled-analytics-container > div:nth-child(2) {
    width: 50%;
}

.unhandled-analytics-container > div:nth-child(2) > input {
    width: 40%;
}

.unhandled-analytics-container > div:nth-child(2) > p {
    width: 40%;
}